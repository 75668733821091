.product-image-drop-target {
  background-color:lightgray; 
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 4px darkgray;
  border-radius: 15px;
}

.product-image-drop-content.h3 {
  color: darkgray;
}

/* styles.css */

.print-only {
  display: none; /* Hidden by default */
}

.hidden-on-screen {
  display: none;
}

@media screen {
  .hidden-on-screen {
    display: none;
  }
}

@media print {
  .hidden-on-screen {
    display: block;
  }
}

@media print {
  .no-print {
    display: none;
  }
}

.show{
  display:block;
}

.hide{
  display:none;
}